/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Mon espace labo';
export const txtnombredoc = 'document';
export const txtnombredocpluriel = 'documents';
export const titleAlveole = 'Mon espace labo';

// TODO arriver a faire fonctionner
export const alveoleLogoSvg = "./../images/icon-alv-espace-labo.svg";
/************************ DeV ******************************************************/
export const zAPIdocuments = "https://api-monespacelabo-dev.cristal-hub.fr/customer-documents";
export const zUrlBack = "https://api-monespacelabo-dev.cristal-hub.fr";
export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const urlZeenDoc = "https://www.preprod.crm-cristal.fr/enjinapi/Zeendoc"
/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zAPIdocuments = "https://localhost:8000/customer-documents";
// export const zUrlBackNotifications = "https://localhost:8001";

export const Typedoc =[
    { id: 5, title: 'Rapports d\'analyses' },
];
export const Typedocid = [5];

/**
 * Fin constantes spécifiques alvéole
 */

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
